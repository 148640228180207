<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Historias Clínicas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Ver Fichas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4 style="border-bottom: 1px solid #005f91">
        <strong>LISTADO DE FICHAS</strong>
      </h4>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-2">
          <span><strong>FECHA: </strong></span>
          <Calendar
            v-model="fecha"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          ></Calendar>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>MÉDICO: </strong></span>
          <Dropdown
            :disabled="medicoLogueado"
            v-model="medicoSelected"
            :options="medicos"
            optionLabel="nombre_completo"
            optionValue="nombre_completo"
            :filter="true"
            @change="BuscarFiltro"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>BUSCAR PACIENTE:</strong></span>
          <div class="p-inputgroup">
            <AutoComplete
              field="nombre"
              v-model="clienteSelect"
              :suggestions="clientes"
              placeholder="Escriba Nombre del Paciente"
              v-tooltip.top="'Buscar Todos los Pacientes'"
              @keyup.enter="buscarCliente($event)"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.nombre }}</strong
                  ><span><strong> - CODIGO:</strong></span>
                  {{ slotProps.item.id }}
                </div>
              </template>
            </AutoComplete>
            <Button
              icon="pi pi-search"
              class="p-button-warning"
              v-tooltip.top="'Buscar Paciente'"
              @click="buscarCliente($event)"
            />
          </div>
        </div>

        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="estadoSelected"
            :options="[
              { label: 'PENDIENTE', value: 0 },
              { label: 'ATENDIDO-ENFERMERIA', value: 1 },
              { label: 'ATENDIDO-MEDICO', value: 2 },
              { label: 'NO ATENDIDO', value: 3 },
            ]"
            optionLabel="label"
            optionValue="value"
            :filter="true"
            @change="BuscarFiltro"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="BUSCAR FICHAS"
            icon="pi pi-search"
            class="p-button-primary mr-2"
            v-tooltip.top="'Buscar Productos'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_fichas_consultas"
        key="id"
        :value="fichas_consultas"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Historias..."
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="ACCIONES">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info p-button-sm mr-2"
                v-tooltip.top="'Ver Ficha'"
                @click="VerFicha(data.id)"
              />
            </div>
          </template>
        </Column>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="fecha" header="HORA">
          <template #body="{ data }">
            {{ fechaHora(data.fecha.split(" ")[0], data.fecha.split(" ")[1]) }}
          </template>
        </Column>
        <Column field="cliente_id" header="COD.PACIENTE"></Column>
        <Column field="nombre_cliente" header="PACIENTE"></Column>
        <Column field="fecha_nacimiento" header="EDAD">
          <template #body="{ data }">
            {{ edadMesesCliente(data.fecha_nacimiento) }}
          </template>
        </Column>
        <Column
          field="servicio"
          header="SERVICIO"
          style="font-weight: bold"
        ></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span
              class="customer-badge"
              :class="{
                'status-proposal': data.estado === 0,
                'status-new': data.estado === 1,
                'status-qualified': data.estado === 2,
                'status-unqualified': data.estado === 3,
              }"
            >
              {{ textoEstado(data.estado) }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import HistoriaService from "@/service/HistoriaService";
import MedicoService from "@/service/MedicoService";
import ClienteService from "@/service/ClienteService";

export default {
  data() {
    return {
      enviando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      fichas_consultas: [],
      fecha: new Date(),
      medicoSelected: null,
      medicos: [],
      clienteSelect: null,
      clientes: [],
      estadoSelected: 0,
      nombre_rol_usuario: null,
      medicoLogueado: false,
    };
  },
  auth: null,
  clienteService: null,
  historiaService: null,
  medicoService: null,

  created() {
    this.auth = useAuth();
    this.historiaService = new HistoriaService();
    this.medicoService = new MedicoService();
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.cargarMedicos();
    //this.estadoSelected = this.nombre_rol_usuario == "ENFERMERIA" ? 0 : 1;
  },
  methods: {
    VerFicha(data) {
      this.$router.push(`/historias/${data}/detalle`);
    },
    fechaHora(fecha, hora) {
      if (this.clienteSelect) return fecha + " " + hora;
      else return hora;
    },
    edadMesesCliente(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        meses < 0 ||
        (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
        meses += 12;
      }
      return edad + " AÑOS Y " + meses + " MESES";
    },
    buscarCliente() {
      let datos = {
        texto: this.clienteSelect ?? null,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el Paciente",
            life: 3000,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    BuscarFiltro() {
      this.fichas_consultas = [];
      this.enviando = true;
      this.cargarFichasConsultas();
    },
    cargarFichasConsultas() {
      let datos = {
        fecha: this.fecha,
        nombre_medico: this.medicoSelected ?? "TODOS",
        nombre_cliente: this.clienteSelect ?? "TODOS",
        estado: this.estadoSelected ?? 0,
      };
      this.historiaService
        .buscarFichas(datos)
        .then((response) => {
          this.fichas_consultas = response.fichas_consultas || [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];
        this.nombre_rol_usuario = data.nombre_rol_usuario ?? null;

        if (this.nombre_rol_usuario != "MEDICO") {
          this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
          this.medicoSelected = this.medicos[0].nombre_completo ?? null;
          this.estadoSelected = 0;
          this.medicoLogueado = false;
        } else {
          this.medicoLogueado = true;
          this.medicoSelected = this.auth.user.name ?? null;
          console.log(this.medicoSelected);
          this.estadoSelected = 1;
        }
        this.BuscarFiltro();
      });
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "PENDIENTE";
        case 1:
          return "ATENDIDO-ENFERMERIA";
        case 2:
          return "ATENDIDO-MEDICO";
        case 3:
          return "NO ATENDIDO";
        default:
          return "PENDIENTE";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 5px;
}
.p-invalid {
  color: red;
}
</style>
